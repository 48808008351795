.app .contents {
  width: 800px;
  height: 800px;
}

.app .contents .mode {
  padding: 10px 0;
}

.app .contents .code {
  background: #f5f5f5;
  overflow: auto;
  display: none;
}